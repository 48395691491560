import { ltn, tn } from "../../../../../../translation";
import { Button, Text } from "@mantine/core";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../../../../constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../context/AppContext";
import { useDebouncedValue } from "@mantine/hooks";

export const CancelProgram = (props: any) => {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { data, onClose } = props;
  const { reloader, setReloader } = useContext(AppContext);
  const [debounced_data] = useDebouncedValue(data, 200);
  const [applications, setApplications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    const resultList = pb
      .collection("applications_view")
      .getList(1, 50, {
        filter: `main_status != "rejected" && main_status != "excluded" && main_status != "withdrawn" && program = "${data?.record?.id}"`,
      })
      ?.then((res: any) => {
        setApplications(res?.items);
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        padding: "20px",
        width: "100%",
      }}
    >
      <Text fw={600} color="#5c5c5c">
        {debounced_data?.record?.[1]
          ? ltn("cancel_programs")
          : ltn("cancel_program")}
      </Text>
      <Text fz="xs" mt="xs" color="#5c5c5c">
        {ltn("number_of_applicants")} {applications?.length}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          loading={buttonLoading}
          color="red"
          mx={5}
          onClick={
            data?.record?.[0]
              ? () => {
                  data?.record?.forEach(async (record: any) => {
                    await pb.collection(data?.table?.table).update(record, {
                      status: "canceled",
                    });
                  });
                  onClose({});
                  setReloader(!reloader);
                }
              : async () => {
                  await pb
                    .collection(data?.table?.table)
                    .update(data?.record?.id, {
                      status: "canceled",
                    })
                    ?.then(async (res: any) => {
                      setButtonLoading(true);
                      for (const application of applications || []) {
                        const record = await pb
                          .collection("applications")
                          .update(application?.id, {
                            main_status: "canceled_program",
                            applicant_status: "canceled_program",
                            recruiter_status: "canceled_program",
                          });
                      }
                    });
                  onClose({});
                  setReloader(!reloader);
                }
          }
        >
          {data?.record?.[1] ? tn("Cancel Progarms") : tn("Cancel Progarm")}
        </Button>
        <Button
          variant="outline"
          color="gray"
          mx={5}
          onClick={() => onClose({})}
        >
          {tn("Cancel")}
        </Button>
      </div>
    </div>
  );
};
