import { Button, Text } from "@mantine/core";
import { useContext } from "react";
import { CMS_ENDPOINT } from "../../../constants";
import {TranslationContext} from "../../../context/TranslationContext";
import { tn } from "../../../translation";
import { fv } from "../../../main_components/Functions";

export const ProgramDetails = ({ data, isTab, company_name, }: any) => {
  const isMobile = false
  const { locale,translation } = useContext(TranslationContext);
  const expandColumn = data?.expand?.program;
  console.log("expandColdataumn", data);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: !isMobile ? "calc(50% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <DataBlock
            isTab={isTab} isMobile={isMobile}
            name={tn("Program nature")}
            value={fv((data?.nature ?? expandColumn?.nature),"program_nature",translation)?.[locale]}
          />
        </div>
        <div
          style={{
            marginTop: isMobile ? "0px" : "10px",
            width: !isMobile ? "calc(50% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <DataBlock
            isTab={isTab} isMobile={isMobile}
            name={tn("Program type")}
            value={fv((data?.type ?? expandColumn?.type),"program_type",translation)?.[locale]}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: !isMobile ? "calc(50% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <DataBlock
            isTab={isTab} isMobile={isMobile}
            name={tn("program period")}
            value={fv((data?.period ?? expandColumn?.period),"period",translation)?.[locale]}
          />
        </div>
        <div
          style={{
            marginTop: isMobile ? "0px" : "10px",
            width: !isMobile ? "calc(50% - 10px)" : "calc(100% - 10px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <DataBlock
            isTab={isTab} isMobile={isMobile}
            name={tn("Program start date")}
            value={new Date(
              data?.start_date ?? expandColumn?.start_date
            )?.toLocaleDateString("en-GB")}
          />
        </div>
      </div>

      <div style={{
        width: "100%",  
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px",
        gap: "10px",
      }}>
        <DataBlockLarge
          isTab={isTab} isMobile={isMobile}
          name={tn("Program brief")}
          value={data?.brief ?? expandColumn?.brief}
        />
         <DataBlockLarge
          isTab={isTab} isMobile={isMobile}
          name={tn("Program-specific attachments")}
          value={<div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
          
          }}>
            <Button 
            disabled={!(data?.attachment_1 || expandColumn?.attachment_1)}
            variant="light"
            color="color3"
            onClick={() => {
              window.open(
                data?.attachment_1?`${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.attachment_1}?token=`:
                `${CMS_ENDPOINT}/api/files/${expandColumn?.collectionId}/${expandColumn?.id}/${expandColumn?.attachment_1}?token=`,
                "_blank",
                "noreferrer"
              );
            
            }}
            >
            {tn("Download first attachment")}
          </Button>
          <Button   variant="light"
           disabled={!(data?.attachment_2 || expandColumn?.attachment_2)}
            color="color3">
            {tn("Download second attachment")}
          </Button>
          </div>}
        />
      </div>
      <div style={{
        width: "100%",  
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px",
        gap: "10px",
      }}>
        <DataBlockLarge
          isTab={isTab} isMobile={isMobile}
          name={tn("Program objectives")}
          value={data?.objective ?? expandColumn?.objective}
        />
          <DataBlockLarge
          isTab={isTab} isMobile={isMobile}
          name={tn("Additional instructions")}
          value={data?.additional_instructions ?? expandColumn?.additional_instructions}
        />
      </div>
      <div style={{
        width: "100%",  
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px",
        gap: "10px",
        marginBottom: "10px",
      }}>
        <DataBlockLarge
          isTab={isTab} isMobile={isMobile}
          name={tn("Requirements to join the program")}
          value={((data?.has_interview || expandColumn?.has_interview) || (data?.has_filtration_test || expandColumn?.has_filtration_test)) ? <div>
           {(data?.has_interview || expandColumn?.has_interview)&& <li>{tn("interview")}</li>}
           {(data?.has_filtration_test || expandColumn?.has_filtration_test)&& <li>{tn("Filtration test")}</li>}
          </div>:tn("nothing")}
        />
          <DataBlockLarge
          isTab={isTab} isMobile={isMobile}
          name={tn("Personal and professional skills required")}
          // value={data?.required_skills?.map((item)=>item?.[locale]) ?? expandColumn?.required_skills?.map((item)=>item?.[locale])}
          value={
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
            }}>
              {data?.required_skills?.map((item)=> <li>{fv(item,"skills",translation)?.[locale]}</li>) ?? expandColumn?.required_skills?.map((item)=>
              <li>{fv(item,"skills",translation)?.[locale]}</li>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

const DataBlock = ({ name, value, isTab,isMobile}: any) => {
  return (
    <>
      {" "}
      <Text
        style={{
          width: "150px",
          color: "#4F5152",
          fontSize: !isMobile ? "14px" : "10px",
          fontWeight: "500",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minWidth: !isMobile ? "150px" : "100px",
          textAlign: "center",
          background: "rgb(255, 255, 255)",
          borderRadius: "5px",
          padding: "5px 10px",
          fontSize: !isMobile ? "14px" : "10px",
          fontWeight: "500",
          color: "#FFB004",
          whiteSpace: "pre-wrap",
        }}
      >
        {value}
      </Text>
    </>
  );
};

const DataBlockLarge = ({ name, value, isMobile }: any) => {
  return (
    <div style={{
      width: "100%",
      display: "flex",
      alignItems: "start",
    flexDirection: "column",
    }}>
      {" "}
      <Text
        style={{
          color: "#4F5152",
          fontSize: isMobile ? "14px" : "10px",
          fontWeight: "500",
          background: "#f6f6f6",
          transform: "translate(-10px, 10px)",
          borderRadius: "5px",
          padding: "0px 10px",
        }}
      >
        {name}
      </Text>
      <Text
        style={{
          minWidth: '100%',
          background: "rgb(255, 255, 255)",
          borderRadius: "5px",
          padding: "15px 10px",
          fontSize: isMobile ? "14px" : "10px",
          fontWeight: "500",
          border: "1px solid #FFB004",
          color: "#4F5152",
          minHeight: "115px",
          maxHeight: "115px",
          overflowY: "auto",
        }}
      >
        {value}
      </Text>
      </div>
  );
};

