import { useContext } from "react";
import { DataContext } from "../../../context/DataContext";

import { CMS_ENDPOINT } from "../../../constants";
import { BannersContext } from "../../../context/BannersContext";
import { IconSquareRoundedX } from "@tabler/icons-react";
import { ltn } from "../../../translation";

export const ClickableActionsMap = (column, data, table) => {
  const {
    setViewDepartment,
    setHRviewProgram,
    setApplicantViewProgram,
    setEvaluationInterview,
    setChangeCommencementDate,
    setActiveProgramRecruiter,
    setActiveProgramsApplicant,
    setProgramEvaluation,
    setWithdrawalReason,
    setViewPermission,
    setRejectProgramReason,
    setShowAbout,
    setTerminateApplicantView,
    setProgramEvaluationView,
    setViewRecruiter,
    setLocationsDetails
  }: any = useContext(DataContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const dialogMassage = ltn("application_must_be_active");

  const getClickActionHandler = () => {

    switch (column?.click_action?.name) {
      case "HR_view_recruiter":
        return () => {
          setViewRecruiter({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "HR_view_department":
        return () => {
          setViewDepartment({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "HR_view_program":
        return () => {
          setHRviewProgram({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "applicant_view_program":
        return () => {
          setApplicantViewProgram({
            id: data?.id,
            record: data,
            table: table,
          });
        };
      case "active_program_recruiter":
        return () => {
          setActiveProgramRecruiter({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "active_programs_applicant":
        return () => {
          setActiveProgramsApplicant({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "link":
        return () => {
          openCompanyLink(data);
        };

      case "open_cv":
        return () => {
          if (data?.cv) {
            window.open(
              `${CMS_ENDPOINT}/api/files/_pb_users_auth_/${data?.applicant}/${data?.cv}?token=`,
              "_blank",
              "noreferrer"
            );
          }
        };
      case "open_training_request_letter":
        return () => {
          if (data?.training_request_letter) {
            window.open(
              `${CMS_ENDPOINT}/api/files/${data?.collectionId}/${data?.id}/${data?.training_request_letter}?token=`,
              "_blank",
              "noreferrer"
            );
          }
        };
      case "evaluation":
        return () => {
          setEvaluationInterview({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "program_evaluation":
        return () => {
          // if (!data?.program_evaluation) {
            setProgramEvaluation({
              id: data.id,
              record: data,
              table: table,
            });
          // }
        };
        case "program_evaluation_view":
          return () => {
            if (data?.program_evaluation) {
              setProgramEvaluationView({
                id: data.id,
                record: data,
                table: table,
              });
            }
          };
      case "change_date":
        return () => {
          setChangeCommencementDate({
            id: data.id,
            record: data,
            table: table,
          });
        };
      case "location_or_interview":
        return () => {
          if(
            data?.interview_type == "in_person"
          ){
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${data?.location}}`,
              "_blank",
              "noreferrer"
            );
          }else{
            window.open(
              data?.personal_interview_link,
              "_blank",
              "noreferrer"
            );
          }
          }
        
      case "withdrawal_reason":
        return () => {
          setWithdrawalReason({
            id:data?.id,
            record: data,
            table: table,
          });
        };
      case "permissions":
        return () => {
          setViewPermission({
            id:data?.id,
            record: data,
            table: table,
          });
        };
      case "reject_program_reason":
        return () => {
          if (data?.reject_reason.length > 0) {
            setRejectProgramReason({
              id:data?.id,
              record: data,
              table: table,
            });
          }
        };
      case "application_link":
        return () => {
          if (data?.application_link) {
            window.open(data?.application_link, "_blank", "noreferrer");
          }
        };
      case "applications_history_applicant":
        return () => {
          if (
            data?.main_status != "excluded" &&
            data?.main_status != "rejected"  && 
            data?.main_status != "withdrawn"
          ) {
            setActiveProgramsApplicant({
              id:data?.id,
              record: data,
              table: table,
            });
          } else {
            setTopBanner({
              message: dialogMassage,
              color: "#c02d2d",
              top: "83px",
              backgroundColor: "#fff2f2eb",
              icon: <IconSquareRoundedX />,
            });
          }
        };
      case "show_about":
        return () => {
          setShowAbout({
            id:data?.id,
            record: data,
            table: table,
          });
        };
      case "applicant_evaluation":
        return () => {
          setTerminateApplicantView({
            id: data?.id,
            record: data,
            table: table,
          
          });
        };
        case "location_details":
          return () => {
            if(
              data?.interview_type == "in_person"
            ){
              setLocationsDetails({
                id: data?.id,
                record: data,
                table: table,
              });
            }
            }
      default:
        () => {};
        <></>;
    }
  };
  return getClickActionHandler();
};
const openCompanyLink = (data: any) => {
  if (data?.web) {
    window.open(
      data?.web?.includes("https://") ? data?.web : "http://" + data?.web,
      "_blank",
      "noreferrer"
    );
  } else if (data?.expand?.company?.web) {
    window.open(
      data?.expand?.company?.web?.includes("https://")
        ? data?.expand?.company?.web
        : "http://" + data?.expand?.company?.web,
      "_blank",
      "noreferrer"
    );
  } else {
  }
};
