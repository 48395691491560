import {
  Paper,
  createStyles,
  TextInput,
  SegmentedControl,
  Select,
  Button,
  FileInput,
  Text,
  NumberInput,
  rem,
  NativeSelect,
  FileButton,
  Center,
  Textarea,
  Avatar,
} from "@mantine/core";
import { IconPencil, IconPhoto, IconUpload, IconX } from "@tabler/icons-react";

import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { useViewportSize } from "@mantine/hooks";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { MIME_TYPES } from "@mantine/dropzone";
import { motion } from "framer-motion";
import { containsArabicNumbers, convertArabicNumbersToEnglish, convertEnglishNumbersToArabic, vf } from "../../../main_components/Functions";

export function FillPersonalInfo() {
  const { locale, translation }: any = useContext(TranslationContext);
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const { height, width } = useViewportSize();
  const [avatar, setAvatar] = useState("");
  const [onHover, setOnHover] = useState(false)
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber]: any = useState("");
  const [countryNum, setCountryNum] = useState("+966");

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [maxGPA, setMaxGPA] = useState(5);
  const universities = vf("university");
  const majors = vf("major");
  const semesters = vf("semester");
  const degrees = vf("degree");
  const graduation_years = vf("graduation_year");
  const GPAerror = vtn("GPA");
  const MobileError = vtn("mobile");
  const form: any = useForm({
    initialValues: {
      phone: "",
      gender: "male",
      university: "",
      major: "",
      GPA: 0,
      CV: "",
      avatar: "",
      about: "",
      country_mobile_number:"",
      graduation_year:"2023",
      semester:"",
      degree:"",
      
    },

    validate: {
      phone: (value) => ((!isNaN(phoneNumber) && phoneNumber.length == 9 )? null : MobileError),
      GPA: (value) => (value > 0 && value <= maxGPA ? null : GPAerror),
    },
  });
  const divider = (
    <div
      style={{
        width: width < 500 ? "50%" : "1px",
        height: width < 500 ? "1px" : "15px",
        background: "white",
        margin: width < 500 ? "10px 0px" : "0px 5px",
      }}
    />
  );

  console.log("foreeeregrors", form.values.GPA);
  const [GPAfirst, setGPAfirst] = useState("")
  useEffect(() => {
    form.setFieldValue("GPA", null);
    setGPAfirst("");
  }, [maxGPA]);
  useEffect(() => {
      const formatted = convertArabicNumbersToEnglish(GPAfirst);
      const value:any = !isNaN(formatted)? parseFloat(formatted):null;
      if(
        value > 0
      ){
        if(value > maxGPA){
          form.setFieldValue("GPA", maxGPA);
          setGPAfirst(
            !containsArabicNumbers(GPAfirst) ?
            maxGPA.toString():
            convertEnglishNumbersToArabic(maxGPA.toString())
          );
        }else{
          form.setFieldValue("GPA", value);
        }
      }else{
        form.setFieldValue("GPA", null);
      }
  }, [GPAfirst]);



  useEffect(() => {
    form.setFieldValue("phone", countryNum + phoneNumber);
  }, [countryNum]);
  useEffect(() => {
    form.setFieldValue("phone", countryNum + phoneNumber);
  }, [phoneNumber]);
  return (
    <form
      onSubmit={form.onSubmit((value: any) => { 
          const data = {
            mobile: value.phone,
            gender:
              value.gender ,
            university: value.university,
            major: value.major,
            GPA: value.GPA,
            full_info: true,
            GPA_out_of: maxGPA,
            about: value.about,
            country_mobile_number:countryNum,
            graduation_year:value.graduation_year,
            semester:value.semester,
            degree:value.degree,
          }; 
          console.log("datadatadatadddatadata",data);
          try {
          
          const formData = new FormData();
          formData.append("avatar", value?.avatar);
          formData.append("cv", value?.CV);
         
            const record = pb
            .collection("applicants")
            .update(pb?.authStore?.model?.id, data)?.then(()=>{
              const record_ = pb
              .collection("applicants")
              .update(pb?.authStore?.model?.id, formData);
              navigate("/applicant/programs_search");
            });
          } catch (error) {
          }
        
      })}
    >
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background:
            "radial-gradient(circle, #020024 0%, #020024e3 0%, rgba(33,3,63) 100%)",
          position: "absolute",
          zIndex: "10",
          display: "flex",
          flexDirection: "column",
          justifyContent: height > 1250 ? "center" : "start",
          alignItems: "center",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            borderRadius: "15px",
            background: "#4f4f4fa4",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            width: "90%",
            height: "902px",
            maxWidth: "545px",
            minHeight: "1030px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px 10px",
            // marginBottom: "50px",
          }}
        >
          <div
            style={{
              color: "#69DCBF",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              margin: "20px 10px 10px 10px",
            }}
          >
            {tn("Profile")}
          </div>
          <FileButton  maxSize={1024 ** 2}
          {...form.getInputProps("avatar")}
        onChange={async (file: any) => {
          // const formData = new FormData();
          // formData.append("avatar", file);
          // const record = pb
          //   .collection("applicants")
          //   .update(pb?.authStore?.model?.id, formData);
          form.setFieldValue("avatar", file);
          setAvatar(URL.createObjectURL(file));


        }}
        accept="image/png,image/jpeg"
      >
        {(props) => (
          <motion.div
            {...props}
            style={{
              cursor: "pointer",
              padding: "1px",
              position: "relative",
            }}
            onHoverStart={() => {
              setOnHover(true);
            }}
            onHoverEnd={() => {
              setOnHover(false);
            }}
          >
            <Avatar styles={()=>({
                  placeholderIcon:{
                    width: "120%",
                    height: "120%",
                    color: "#ffffff",
                    minWidth: "120%",
                    minHeight: "120%",
                   
                  }
                })} src={avatar} size={120} radius="100%" variant="outline" />
            <motion.div
              animate={{
                width: onHover ? "130px" : "36px",
                height: onHover ? "130px" : "36px",
              }}
              style={{
                width: "23px",
                height: "23px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#69dcbfac",
                border: "1px solid #ffffff9b",
                borderRadius: "1000px",
                position: "absolute",
                bottom: "0px",
                right: "0px",
                padding: "2px",
                transform: "translate(4px,4px)",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {avatar && (
                <motion.div
                  onClick={async (e) => {
                    e.stopPropagation();
                    setAvatar("");
                    form.setFieldValue("avatar", "");
                  }}
                  animate={{
                    width: onHover ? "30px" : "0px",
                    height: onHover ? "30px" : "0px",
                  }}
                  transition={{ duration: 0.3 }}
                  style={{
                    width: "0px",
                    height: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#F9837C",
                    borderRadius: "1000px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    padding: "2px",
                    transform: "translate(4px,4px)",
                    flexDirection: "column",
                  }}
                >
                  <IconX color="white" size={20} />
                </motion.div>
              )}
              <IconPencil color="rgba(33,3,63)" />
              <motion.div
                style={{
                  marginTop: "2px",
                  fontSize: "12px",
                  fontWeight: 700,
                  display: "flex",
                }}
                animate={{
                  display: onHover ? "flex" : "none",
                  color: "rgba(33,3,63)",
                  width: "70%",
                }}
              >
                {tn("Uploading avatar")}
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </FileButton>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              width: "calc(100% - 60px)",
            }}
          >
            {" "}
            <div style={{ display: "flex", alignItems: "end" }}>
              <TextInput
                error={form.errors.phone}
                required
                onChange={(e) => setPhoneNumber(convertArabicNumbersToEnglish(e.target.value))}
                type="text" // Changed to text to handle both Arabic and English characters
                label={tn("Mobile")}
                rightSection={
                  <Select
                   size="xs"
                    onChange={(e) => setCountryNum(e)}
                    value={countryNum}
                    data={[
                      { value: "+1", label: "🇺🇸" },
                      { value: "+44", label: "🇬🇧" },
                      { value: "+33", label: "🇫🇷" },
                      { value: "+49", label: "🇩🇪" },
                      { value: "+81", label: "🇯🇵" },
                      { value: "+86", label: "🇨🇳" },
                      { value: "+91", label: "🇮🇳" },
                      { value: "+61", label: "🇦🇺" },
                      { value: "+7", label: "🇷🇺" },
                      { value: "+55", label: "🇧🇷" },
                      { value: "+20", label: "🇪🇬" },
                      { value: "+966", label: "🇸🇦" },
                      { value: "+971", label: "🇦🇪" },
                      { value: "+962", label: "🇯🇴" },
                      { value: "+965", label: "🇰🇼" },
                      { value: "+961", label: "🇱🇧" },
                      { value: "+964", label: "🇮🇶" },
                      { value: "+973", label: "🇧🇭" },
                      { value: "+968", label: "🇴🇲" },
                      { value: "+974", label: "🇶🇦" },
                      { value: "+52", label: "🇲🇽" },
                      { value: "+45", label: "🇩🇰" },
                      { value: "+64", label: "🇳🇿" },
                      { value: "+34", label: "🇪🇸" },
                      { value: "+39", label: "🇮🇹" },
                      { value: "+41", label: "🇨🇭" },
                    ]}
                    styles={(theme) => ({
                      label: {
                        color: "#ffffff",
                      },
                      input: {
                        fontSize: "13px",
                        border: "0px solid #69DCBF",
                        borderRadius: "0px",
                        color: "white",
                        backgroundColor: "transparent",
                        // borderBottom: "1px solid #69dcbf86",
                        "&:focus-within": {
                          // borderBottom: "1px solid #69DCBF",
                        }, 
                      },
                     
                    })}
                  />
                }
                rightSectionWidth={60}
                withAsterisk={false}
                styles={(theme) => ({
                  label: {
                    // marginLeft: "-30px",
                    color: "white",
                  },
                  input: {
                    // textAlign: "end",
                    border: "0px solid #69DCBF",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #69dcbf86",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF", 
                    },
                   
                  }, error:{
                    marginBottom:"-13px",
                    fontSize:"12px",
                  }
                })}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Text
                mt={10}
                style={{
                  color: "#FFF",
                  textAlign: "right",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {tn("Gender")}
              </Text>
              <SegmentedControl
                {...form.getInputProps("gender")}
                //   style={{height:"30px"}}
                styles={() => ({
                  root: {
                    color: "#FFF",
                    backgroundColor: "transparent",
                    border: "1px solid #ffffffdc",
                  },
                  label: {
                    color: "#FFF",
                    height: "20px",
                    borderRadius: "2px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 10px",
                    "&:hover": {
                      color: "#FFF",
                    },
                    '&[data-active="true"]': {
                      color: "#FFF",
                    },
                    "&[data-active]:hover": {
                      color: "#FFF",
                    },
                  },
                  indicator: {
                    color: "#FFF",
                    backgroundColor: "#69DCBF !important",
                    borderRadius: "2px",
                  },
                  control: {
                    width: "60px",
                    color: "#FFF",
                    // backgroundColor: "#91919160 !important",
                    borderRadius: "2px",
                  },
                  controlActive: { color: "#FFF" },
                })}
                data={[
                  { label: tn("Male"), value: "male" },
                  { label: tn("Female"), value: "female" },
                ]}
              />
            </div>
          </div>
          <Select
            {...form.getInputProps("university")}
            style={{ width: "calc(100% - 60px)" }}
            searchable
            withAsterisk={false}
            data={
              universities?.[0]
                ? universities?.map((option: any) => ({
                  value: option?.name,
                  label: option?.[locale],
                  }))
                : []
            }
            required
            mt={10}
            variant="filled"
            styles={(theme) => ({
              label: {
                color: "#ffffff",
              },
              input: {
                borderRadius: "0px",
                color: "white",
                backgroundColor: "transparent",
                borderBottom: "1px solid #69dcbf86",
                "&:focus-within": {
                  border: "0px solid #69DCBF",
                  borderBottom: "1px solid #69DCBF",
                },
              },
            })}
            label={tn("University")}
            size="md"
          />
          <Select
            {...form.getInputProps("major")}
            style={{ width: "calc(100% - 60px)" }}
            searchable
            withAsterisk={false}
            data={
              majors?.[0]
                ? majors?.map((option: any) => ({
                  value: option?.name,
                  label: option?.[locale],
                  }))
                : []
            }
            required
            mt={10}
            variant="filled"
            styles={(theme) => ({
              label: {
                color: "#ffffff",
              },
              input: {
                borderRadius: "0px",
                color: "white",
                backgroundColor: "transparent",
                borderBottom: "1px solid #69dcbf86",
                "&:focus-within": {
                  border: "0px solid #69DCBF",
                  borderBottom: "1px solid #69DCBF",
                },
              },
            })}
            label={tn("University Major")}
            size="md"
          />
             <Select
            {...form.getInputProps("degree")}
            style={{ width: "calc(100% - 60px)" }}
            searchable
            withAsterisk={false}
            data={
              degrees?.[0]
                ? degrees?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            required
            mt={10}
            variant="filled"
            styles={(theme) => ({
              label: {
                color: "#ffffff",
              },
              input: {
                borderRadius: "0px",
                color: "white",
                backgroundColor: "transparent",
                borderBottom: "1px solid #69dcbf86",
                "&:focus-within": {
                  border: "0px solid #69DCBF",
                  borderBottom: "1px solid #69DCBF",
                },
              },
            })}
            label={tn("Degree")}
            size="md"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "calc(100% - 60px)",
              height: "80px",
              borderBottom: !form.errors.GPA?"1px solid #69dcbf86":"1px solid #fa5252",
              paddingBottom: "0px",
            }}
          >
            <Text
              mt={10}
              style={{
                color: "#FFF",
                textAlign: "right",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              {tn("GPA")}
            </Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
            <TextInput
  required
  style={{ width: "calc(100% - 150px)" }}
  withAsterisk={false}
  onBlur={(e) => {
      if (!form?.values?.GPA) {
        setGPAfirst("");
      }
  }}
  onChange={(e) => {
    setGPAfirst(e.target.value);
  }}
  value={
    GPAfirst
  }
  variant="filled"
  styles={(theme) => ({
    label: {
      color: "white",
    },
    input: {
      borderRadius: "0px",
      color: "white",
      backgroundColor: "transparent",
      border: "0px solid #69DCBF",
    },
    error: {
      marginBottom: "-10px",
      marginTop: "10px",
      fontSize: "12px",
      border: "0px solid #69DCBF",
    },
  })}
  size="md"
/>
<div
  style={{
    border: "1px solid #ffffffdc",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
  }}
>
  <SegmentedControl
    onChange={(value) => {
      setMaxGPA(parseFloat(value));
    }}
    style={{ width: "70px" }}
    styles={() => ({
      root: {
        color: "#FFF",
        backgroundColor: "transparent",
      },
      label: {
        color: "#FFF",
        height: "25px",
        borderRadius: "2px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 10px",
        "&:hover": {
          color: "#FFF",
        },
        '&[data-active="true"]': {
          color: "#FFF",
        },
        "&[data-active]:hover": {
          color: "#FFF",
        },
      },
      indicator: {
        color: "#FFF",
        backgroundColor: "#69DCBF !important",
        borderRadius: "2px",
      },
      control: {
        color: "#FFF",
        borderRadius: "2px",
      },
      controlActive: { color: "#FFF" },
    })}
    data={[
      { label: locale == "en" ? "5" : "٥", value: "5" },
      { label: locale == "en" ? "4" : "٤", value: "4" },
    ]}
  />

              </div>
            </div>
            <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              width: "calc(100% - 0px)",
            }}
          >
            <Select 
            {...form.getInputProps("graduation_year")}
             style={{ width: "calc(50% - 10px)" }}
            searchable
            withAsterisk={false}
            defaultValue={"2023"}
            data={
              graduation_years?.[0]
                ? graduation_years?.map((option: any) => ({
                  value: option?.name,
                    label: option?.[locale],
                  }))?.reverse()
                : []
            
            //  [
            //   // {value:"2010",label:"2010"},
            //   // {value:"2011",label:"2011"},

            //   // {value:"2012",label:"2012"},
            //   // {value:"2013",label:"2013"},
            //   // {value:"2014",label:"2014"},
            //   // {value:"2015",label:"2015"},
            //   // {value:"2016",label:"2016"},
            //   {value:"2017",label:"2017"},
            //   {value:"2018",label:"2018"},
            //   {value:"2019",label:"2019"},
            //   {value:"2020",label:"2020"},
            //   {value:"2021",label:"2021"},
            //   {value:"2022",label:"2022"},
            //   {value:"2023",label:"2023"},
            //   {value:"2024",label:"2024"},
              
            //  ]?.reverse()
            }
            required
            mt={10}
            variant="filled"
            styles={(theme) => ({
              label: {
                color: "#ffffff",
              },
              input: {
                borderRadius: "0px",
                color: "white",
                backgroundColor: "transparent",
                borderBottom: "1px solid #69dcbf86",
                "&:focus-within": {
                  border: "0px solid #69DCBF",
                  borderBottom: "1px solid #69DCBF",
                },
              },
            })}
            label={tn("Graduation Year")}
            size="md"/>
             <Select 
            {...form.getInputProps("semester")}
             style={{ width: "calc(50% - 10px)" }}
            searchable
            withAsterisk={false}
            data={
              semesters?.[0]
                ? semesters?.map((option: any) => ({
                  value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            required
            mt={10}
            variant="filled"
            styles={(theme) => ({
              label: {
                color: "#ffffff",
              },
              input: {
                borderRadius: "0px",
                color: "white",
                backgroundColor: "transparent",
                borderBottom: "1px solid #69dcbf86",
                "&:focus-within": {
                  border: "0px solid #69DCBF",
                  borderBottom: "1px solid #69DCBF",
                },
              },
            })}
            label={tn("Semester")}
            size="md"/>
          </div>
            <div
              style={{
                // border: "1px solid #69dcbfdc",
                borderRadius: "4px",
                display: "flex",
                alignItems: "end",
              }}
            >
              <FileInput
                 maxSize={1024 ** 2}
                accept={MIME_TYPES.pdf}
                {...form.getInputProps("CV")}
                // style={{ width: "calc(100% - 60px)",}}
                withAsterisk={false}
                //   {...form.getInputProps("firstName")}
                icon={<IconUpload size={rem(14)} />}
                mt={30}
                variant="filled"
                style={{ width: "calc(100% - 50px)" }}
                styles={(theme) => ({
                  label: {
                    color: "white",
                  },
                  input: {
                    
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid #69dcbf86",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                })}
                label={tn("Upload CV")}
                size="md"
                valueComponent={(value: any) => {
                  return (
                    value?.value?.name && (
                      <Center
                        mx="md"
                        inline
                        sx={(theme) => ({
                          backgroundColor: " #a0a0a094",
                          fontSize: theme.fontSizes.xs,
                          padding: `${rem(3)} ${rem(7)}`,
                          borderRadius: theme.radius.sm,
                        })}
                      >
                        <IconPhoto
                          size={rem(14)}
                          style={{
                            margin: rem(5),
                            marginTop: "0",
                            marginBottom: "0",
                          }}
                        />
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: rem(200),
                            display: "inline-block",
                          }}
                        >
                          {value?.value?.name}
                        </span>
                      </Center>
                    )
                  );
                }}
              />
              <div
                style={{
                  borderBottom: "1px solid #69dcbf86",
                  paddingBottom: "10px",
                }}
              >
                <FileButton  maxSize={1024 ** 2}
                  {...form.getInputProps("CV")}
                  // multiple
                  accept={MIME_TYPES.pdf}
                >
                  {(props) => (
                    <Button
                      style={{
                        background: "#69DCBF",
                        borderRadius: "5.112px",
                        height: "30px",
                      }}
                      {...props}
                    >
                      {tn("Upload")}
                    </Button>
                  )}
                </FileButton>
              </div>
            </div>
            <Text
              style={{
                color: "#FFB004",
                textAlign: "right",
                fontFamily: "IBM Plex Sans Arabic",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "28px",
              }}
            >
              {tn("pdf massage")}
            </Text>
            <div
              style={{
                // border: "1px solid #69dcbfdc",
                borderRadius: "4px",
                display: "flex",
                alignItems: "end",
              }}
            >
              <Textarea
              {...form.getInputProps("about")}
                style={{ width: "calc(100% - 0px)" }}
                mt={10}
                size="md"
                styles={(theme) => ({
                  label: {
                    color: "#ffffff",
                  },
                  input: {
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "transparent",
                    border: "0px solid #69DCBF",

                    borderBottom: "1px solid #69dcbf86",
                    "&:focus-within": {
                      border: "0px solid #69DCBF",
                      borderBottom: "1px solid #69DCBF",
                    },
                  },
                })}
                label={tn("An introductory overview of you")}
                autosize
                minRows={2}
                maxRows={4}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                mt={45}
                mb={10}
                size="xl"
                style={{
                  background: "rgba(105, 220, 191, 0.70)",
                  borderRadius: "5.112px",
                }}
              >
                {tn("Complete Your Profile")}
              </Button>
            </div>
          </div>
        </div>

        {width > 1025 && (
          <img
            src="/white_text_logo.png"
            style={{
              height: "90px",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
              margin: "20px",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px",
            width: "100%",
            maxWidth: "500px",
            flexDirection: width > 500 ? "row" : "column",
          }}
        >
           <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
                  <Text  color="#ffffff" fz="16px" weight={700}>
                    {tn("terms and conditions")}
                  </Text>
                </a>
                {divider}
                <a style={{textDecoration: "none"}} target="_blank" href="https://www.coophub.co/terms">
                  <Text  color="#ffffff" fz="16px" weight={700}>
                    {tn("Usage policy")}
                  </Text>
                </a>
                {divider}
                <Text color="#ffffff" fz="16px" weight={700}>
                  {tn("All rights are save")}
                </Text>
        </div>
      </div>

      <div
        style={{
          height: "100vh",
          width: "100vw",
          backgroundImage: "url(/images/image1.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "grayscale(1)",
        }}
      />
    </form>
  );
}

export default FillPersonalInfo;
