import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { TabsContext } from "../../context/TabsContext";
import { Loader, Select, Text } from "@mantine/core";
import { tn } from "../../translation";
import ReactECharts from "echarts-for-react";
import _ from "lodash";
import { CMS_ENDPOINT } from "../../constants";
import PocketBase from "pocketbase";
import { TranslationContext } from "../../context/TranslationContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useForm } from "@mantine/form";
import { Helmet } from "react-helmet";
const pb: any = new PocketBase(CMS_ENDPOINT);

function Dashboard() {
  const { locale }: any = useContext(TranslationContext);

  const { reloader }: any = useContext(AppContext);
  const [data, setData]: any = useState({});
  const form: any = useForm({
    initialValues: {
      employees_percentage: "2024",
      programs_cost: "2024",
      number_trainees: "2024",
    },
  });
  useEffect(() => {
    const record = pb
      .collection("x_active_programs_count")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_active_programs_count: e,
          };
        });
      });
    const record1 = pb
      .collection("x_applications_count_in_departments")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_applications_count_in_departments: e,
          };
        });
      });
    const record2 = pb
      .collection("x_majors_count")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_majors_count: e,
          };
        });
      });
    const record3 = pb
      .collection("x_programs_count_in_departments")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_programs_count_in_departments: e,
          };
        });
      });
    const record4 = pb
      .collection("x_programs_status_count")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_programs_status_count: e,
          };
        });
      });
    const record5 = pb
      .collection("x_universities_count")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_universities_count: e,
          };
        });
      });
    const record6 = pb
      .collection("x_durations_avg")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            x_durations_avg: e,
          };
        });
      });
    const record7 = pb
      .collection("percentages")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            percentages: e,
          };
        });
      });
    const record8 = pb
      .collection("percentages_years")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            percentages_years: e,
          };
        });
      });
    const record9 = pb
      .collection("applications_view")
      .getFullList("", {})
      ?.then((e: any) => {
        setData((data_: any) => {
          return {
            ...data_,
            applications_view: e,
          };
        });
      });
  }, [reloader]);

  console.log("datadata", data);
  if (!data?.x_majors_count) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 84px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ><Helmet>
      <title>{tn("Dashboard") + " / " + tn("Coophub")}</title>
  </Helmet>
        <Loader />
      </div>
    );
  }
  const PercentageBuilder = (current: any, previous: any) => {
    if (previous === 0) {
      return "";
    }
    return Math.floor(((current - previous) / previous) * 100);
  };
  const all_previos = (name: any, year: any) => {
    switch (year) {
      case "2024":
        return data?.percentages_years?.[0]?.[name + "_" + 2023];
      case "2025":
        return (
          data?.percentages_years?.[0]?.[name + "_" + 2023] +
          data?.percentages_years?.[0]?.[name + "_" + 2024]
        );
      case "2026":
        return (
          data?.percentages_years?.[0]?.[name + "_" + 2023] +
          data?.percentages_years?.[0]?.[name + "_" + 2024] +
          data?.percentages_years?.[0]?.[name + "_" + 2025]
        );
      default:
        return 0;
    }
  };
  return (
    <> <Helmet>
    <title>{tn("Dashboard") + " / " + tn("Coophub")}</title>
</Helmet>
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 84px)",

        padding: "20px",
        paddingTop: "30px",
        background: "#fcfcfc",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        overflow: "auto",
      }}
    >
      <Text
        style={{
          fontSize: "24px",
          fontWeight: 600,
          color: "#333",
          width: "100%",
        }}
      >
        {tn("Dashboard")}
      </Text>
      <div
        style={{
          width: "100%",
          maxWidth: "1500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          marginTop: "20px",
        }}
      >
        {data?.percentages?.[0] && (
          <div>
            <NumberBox
              title={tn("Active programs")}
              value={data?.percentages?.[0]?.current_programs_last_3_months}
              percentage={PercentageBuilder(5, 10)}
              withPercentage={true}
            />

            <NumberBox
              title={tn("Current number of trainees")}
              value={data?.percentages?.[0]?.current_applications_last_3_months}
              percentage={PercentageBuilder(
                data?.percentages?.[0]?.current_applications_last_3_months,
                data?.percentages?.[0]?.current_applications_previous_3_months
              )}
              withPercentage={true}
            />

            <NumberBox
              title={tn("Published programs")}
              value={data?.percentages?.[0]?.programs_last_3_months}
              percentage={PercentageBuilder(
                data?.percentages?.[0]?.programs_last_3_months,
                data?.percentages?.[0]?.programs_previous_3_months
              )}
              withPercentage={true}
            />
            <NumberBox
              select_name="number_trainees"
              form={form}
              title={tn("Total number of trainees")}
              value={
                data?.percentages_years?.[0]?.[
                  "applications_" + form.values.number_trainees
                ]
              }
              percentage={PercentageBuilder(
                data?.percentages_years?.[0]?.[
                  "applications_" + form.values.number_trainees
                ],
                all_previos("applications", form.values.number_trainees)
              )}
              withPercentage={true}
            />
            <NumberBox
              select_name="employees_percentage"
              form={form}
              title={tn(
                "Percentage of trainees out of the number of employees"
              )}
              value={Math.floor(
                (data?.percentages_years?.[0]?.[
                  "applications_" + form.values.employees_percentage
                ] /
                  data?.percentages?.[0]?.number_of_employees) *
                100)
              }
              withPercentage={false}
            />

            <NumberBox
              select_name="programs_cost"
              form={form}
              title={tn("Total programs cost")}
              value={
                data?.percentages_years?.[0]?.[
                  "total_incentives_" + form.values.programs_cost
                ]
              }
              percentage={PercentageBuilder(
                data?.percentages_years?.[0]?.[
                  "total_incentives_" + form.values.programs_cost
                ],
                all_previos("total_incentives", form.values.programs_cost)
              )}
              withPercentage={true}
            />
          </div>
        )}
        <div
          style={{
            width: "100%",
            maxWidth: "1200px",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChartBox
            data={
              data?.x_majors_count?.[0]
                ? data?.x_majors_count?.map((x) => ({
                    value: x.number,
                    name: x.name?.[locale],
                  }))
                : []
            }
            title={tn("Most prominent majors")}
          />
          <ChartBox
            data={
              data?.x_universities_count?.[0]
                ? data?.x_universities_count?.map((x) => ({
                    value: x.number,
                    name: x.name?.[locale],
                  }))
                : []
            }
            title={tn("Most prominent universities")}
          />
          <ChartBox
            data={
              data?.x_active_programs_count?.[0]
                ? data?.x_active_programs_count?.map((x) => ({
                    value: x.number,
                    name: x.name,
                  }))
                : []
            }
            title={tn("Active programs")}
          />
          <BarsChart
            data={
              data?.x_programs_count_in_departments?.[0]
                ? data?.x_programs_count_in_departments?.map((x) => ({
                    value: x.number,
                    name: x.name,
                  }))
                : []
            }
            title={tn("The most published sections of the programs")}
          />
          <ChartBox
          //@ts-ignore
          colors={["#F88379", "#DE8C86", "#69DCBF", "#DE8C86","#F3795C","#FFB004","#692ba7"]}
            data={
              data?.x_programs_status_count?.[0]
                ? data?.x_programs_status_count?.map((x) => ({
                    value: x.number,
                    name: x.name?.[locale],
                  }))
                : []
            }
            title={tn("Programs status")}
          />
          <ChartBox
            data={
              data?.x_applications_count_in_departments?.[0]
                ? data?.x_applications_count_in_departments?.map((x) => ({
                    value: x.number,
                    name: x.name,
                  }))
                : []
            }
            title={tn("Current trainees")}
          />
          <BarsChart
            data={
              data?.x_durations_avg?.[0]
                ? [
                    {
                      value:
                        data?.x_durations_avg?.[0]?.duration_new?.toFixed(2),
                      name: tn("duration_new"),
                    },
                    {
                      value:
                        data?.x_durations_avg?.[0]?.duration_process?.toFixed(2),
                      name: tn("duration_process"),
                    },
                    {
                      value:
                        data?.x_durations_avg?.[0]?.duration_documents?.toFixed(
                          2
                        ),
                      name: tn("duration_documents"),
                    },
                    {
                      value:
                        data?.x_durations_avg?.[0]?.duration_withdraw?.toFixed(
                          2
                        ),
                      name: tn("duration_withdraw"),
                    },
                  ]
                : []
            }
            title={tn("The time the applicant spends in each stage")}
          />{" "}
         { data.applications_view?.[0] && <LineChart
            title={tn("Programs evaluation")}
            data={data.applications_view}
          />}
        </div>
      </div>
    </div></>
  );
}

export default Dashboard;

function ChartBox({ data, title ,colors=[]}) {
  const filteredData = data.filter(function (item) {
    return item.value !== 0;
  });
  return (
    <div
      style={{
        width: "350px",
        height: "210px",
        background: "#fff",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0px 0px 10px 0px #0000001a",
        marginBottom: "20px",
        position: "relative",
        margin: "10px",
        paddingBottom: "20px",
      }}
    >
      <Text
        style={{
          fontSize: "15px",
          fontWeight: 600,
          color: "#6b6b6b",
          width: "100%",
        }}
      >
        {title}
      </Text>
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        notMerge={true}
        theme="echarts_theme"
        option={{
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            align: "right",
            type: "scroll",
            orient: "vertical",
            left: "2px",
            top: 20,
            bottom: 20,
            itemGap: 20,
            itemWidth: 25, // Width of the legend symbol
            itemHeight: 14, // Height of the legend symbol
            formatter: function (name) {
              var maxLength = 20; // Max length of text, after which it will be truncated
              return name.length > maxLength
                ? name.substring(0, maxLength) + "..."
                : name;
            },
            textStyle: {
              fontSize: 16,
              color: "#666",
            },

            selectedMode: true, // Can be set to false if you do not want items to be toggleable
            scrollDataIndex: 0, // Index of the legend data item to be displayed at the top in the initial view
            pageButtonItemGap: 5,
            pageButtonGap: 10,
            pageButtonPosition: "start", // Can be 'start' or 'end'
            pageFormatter: "{current}/{total}", // Custom page formatter
            pageIcons: {
              horizontal: [
                "path://M0,0L12,-12L24,0Z",
                "path://M24,0L12,12L0,0Z",
              ], // Custom icons (optional)
              vertical: ["path://M0,0L12,-12L24,0Z", "path://M24,0L12,12L0,0Z"], // Custom icons (optional)
            },
            pageIconColor: "#5708a6",
            pageIconInactiveColor: "#aaa",
            pageIconSize: 15,
            pageTextStyle: {
              color: "#000",
            },
          },

          color:colors?.[0]?colors: [
            "#BAEDBD", // Soft Mint Green
            "#B1E3FF", // Light Sky Blue
            "#FFD66B", // Mellow Yellow
            "#2a2a2a", // Charcoal Gray
            "#E6E6FA", // Light Lavender
            "#F88379", // Pale Coral
            "#98FF98", // Soft Mint
            "#D3A9A9", // Dusty Rose
            "#87CEEB", // Sky Blue
          ],
          series: [
            {
              name: { title },
              type: "pie",
              radius: ["50%", "80%"],
              avoidLabelOverlap: false,
              startAngle: 90,
              center: ["75%", "45%"], // Adjust the center to give more space for the legend on the left
              data: filteredData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                show: false,
                position: "center",
              },
              labelLine: {
                show: false,
              },
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 3,
              },
            },
          ],
        }} // Keep your chart options here
        className="h-full w-full mt-[-12px]"
      />
    </div>    

  );
}

function BarsChart({ data, title }) {
  const filteredData = data.filter(function (item) {
    return parseInt(item.value) !== 0;
  });
  return (
    <div
      style={{
        width: "720px",
        height: "210px",
        background: "#fff",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0px 0px 10px 0px #0000001a",
        marginBottom: "20px",
        position: "relative",
        margin: "10px",
        paddingBottom: "20px",
      }}
    >
      <Text
        style={{
          fontSize: "15px",
          fontWeight: 600,
          color: "#6b6b6b",
          width: "100%",
        }}
      >
        {title}
      </Text>
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        notMerge={true}
        theme="echarts_theme"
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: filteredData.map((item) => item.name),
            textStyle: {
              fontSize: 16,
              color: "#666",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          yAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
          },
          xAxis: {
            type: "category",
            data: filteredData.map((item) => item.name),
          },

          series: [
            {
              name: title,
              type: "bar",
              data: filteredData?.map((item: any, i) => ({
                value: item.value,
                name: item.name,
                itemStyle: {
                  color:
                    [
                      "#BAEDBD", // Soft Mint Green
                      "#B1E3FF", // Light Sky Blue
                      "#FFD66B", // Mellow Yellow
                      "#2a2a2a", // Charcoal Gray
                      "#E6E6FA", // Light Lavender
                      "#F88379", // Pale Coral
                      "#98FF98", // Soft Mint
                      "#D3A9A9", // Dusty Rose
                      "#87CEEB", // Sky Blue
                    ][i] ??
                    _.shuffle([
                      "#BAEDBD", // Soft Mint Green
                      "#B1E3FF", // Light Sky Blue
                      "#FFD66B", // Mellow Yellow
                      "#2a2a2a", // Charcoal Gray
                      "#E6E6FA", // Light Lavender
                      "#F88379", // Pale Coral
                      "#98FF98", // Soft Mint
                      "#D3A9A9", // Dusty Rose
                      "#87CEEB", // Sky Blue
                    ])[0],
                },
              })),
              itemStyle: {
                borderRadius: 5,
                borderColor: "#fff",
                borderWidth: 3,
              },
            },
          ],
        }} // Keep your chart options here
        className="h-full w-full mt-[-12px]"
      />
    </div>
  );
}

function NumberBox({
  title,
  value,
  percentage,
  withPercentage,
  select_name,
  form,
}: any) {
  // const filteredData = data.filter(function(item) {
  //   return parseInt(item.value)  !== 0;
  // });
  return (
    <div
      style={{
        width: "280px",
        height: "150px",
        background: "#E5ECF6",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0px 0px 4px 0px #0000001a",
        marginBottom: "20px",
        position: "relative",
        margin: "10px",
        paddingBottom: "20px",
        backgroundImage: withPercentage
          ? percentage != 0
            ? percentage > 0
              ? "url(/shapes/Line1.svg)"
              : "linear-gradient(180deg, #E5ECF6 0%, #DE8C86 100%)"
            : ""
          : value > 2
          ? "linear-gradient(180deg, #E5ECF6 0%, #86de95 100%)"
          : "linear-gradient(180deg, #E5ECF6 0%, #DE8C86 100%)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "350px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "#6b6b6b",
            width: "100%",
          }}
          m="md"
        >
          {title}
        </Text>
        {select_name && (
          <Select
            size="xs"
            data={[
              { value: "2023", label: "2023" },
              { value: "2024", label: "2024" },
              { value: "2025", label: "2025" },
              { value: "2026", label: "2026" },
            ]}
            value={form.values[select_name]}
            onChange={(e) => form.setFieldValue(select_name, e)}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: "-20px",
        }}
      >
        <Text
          style={{
            fontSize: "40px",
            fontWeight: 600,
            color: "#6b6b6b",
          }}
          m="md"
        >
          {((isNaN(value)?0:value) ?? 0) + (!withPercentage ? "%" : "")}
        </Text>
        {withPercentage && percentage != "" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Text
              style={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#6b6b6b",
              }}
            >
              {percentage < 0 ? percentage * -1 : percentage}%
              {percentage >= 0 ? "+" : "-"}
            </Text>
            {percentage != 0 && (
              <Icon
                icon="uil:arrow-growth"
                style={{
                  color: "#6b6b6b",
                  transform: `scaleX(-1) scaleY(${percentage > 0 ? 1 : -1})`,
                  fontSize: "18px",
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
// function LineChart({ data, title }) {
//   const form: any = useForm({
//     initialValues: {
//       year: "2024",
//       departments: [],
//       department: "",
//     },
//   });
//   useEffect(() => {
//     const records = pb
//       .collection("departments_view")
//       .getFullList({
//         sort: "-created",
//         // filter : `university_id="${localStorage.getItem("university_id")}"`,
//       })
//       .then((e: any) => {
//         console.log("daeeeeta", e);

//         form.setFieldValue("departments",
//           e?.[0]
//             ? e?.map((item: any) => ({
//                 value: item.id,
//                 label: item.name,
//               }))
//             : []
//         );
//       });
//   }, []);

//   return (
//     <div
//       style={{
//         width: "1100px",
//         height: "210px",
//         background: "#fff",
//         borderRadius: "10px",
//         padding: "10px",
//         boxShadow: "0px 0px 10px 0px #0000001a",
//         marginBottom: "20px",
//         position: "relative",
//         margin: "10px",
//         paddingBottom: "20px",
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Text
//           style={{
//             fontSize: "15px",
//             fontWeight: 600,
//             color: "#6b6b6b",
//             width: "100%",
//           }}
//         >
//           {title}
//         </Text>
//         <Select
//           size="xs"
//           data={[
//             { value: "2023", label: "2023" },
//             { value: "2024", label: "2024" },
//             { value: "2025", label: "2025" },
//             { value: "2026", label: "2026" },
//           ]}
//           value={form.values["year"]}
//           onChange={(e) => form.setFieldValue("year", e)}
//         />
//         <Select
//           size="xs"
//           data={form.values.departments}
//           value={form.values["department"]}
//           onChange={(e) => form.setFieldValue("department", e)}
//         />
//       </div>
//       <ReactECharts
//         style={{ height: "100%", width: "100%" }}
//         notMerge={true}
//         theme="echarts_theme"
//         option={{
//           xAxis: {
//             type: "category",
//             data: ["Q1", "Q2", "Q3", "Q4"],
//           },
//           yAxis: {
//             type: "value",
//           },
//           grid: {
//             left: "0",
//             right: "0",
//             top: "10",
//             bottom: "10",
//             containLabel: true,
//           },
//           series: [
//             {
//               data: [820, 932, 901, 934],
//               type: "line",
//               smooth: true,
//             },
//           ],
//         }} // Keep your chart options here
//       />
//     </div>
//   );
// }

function LineChart({ data, title }) {
  const all_departments_text = tn("All Departments");
  const [departments, setDepartments]:any = useState([])
  const form :any= useForm({
    initialValues: {
      year: "2024",
      departments: [],
      department: "all",
    },
  });

  useEffect(() => {
   
      const records = pb
        .collection("departments_view")
        .getFullList({
          sort: "-created",
          // filter : `university_id="${localStorage.getItem("university_id")}"`,
        })
        .then((e: any) => {
          setDepartments(e);

          form.setFieldValue("departments", [
            { value: "all", label: all_departments_text },
            ...e.map(dept => ({
              value: dept.id,
              label: dept.name
            }))
          ]);
        });
   

   
  }, []);

  // Calculate average program evaluation
  const calculateAverageEvaluation = (selectedDepartment, selectedYear) => {
    const filteredData = data.filter(item => 
      (selectedDepartment === "all" || item.department === selectedDepartment) &&
      new Date(item.program_evaluation_date).getFullYear().toString() === selectedYear
    );
    const quarterlyData = { Q1: [], Q2: [], Q3: [], Q4: [] };

    filteredData.forEach(item => {
      const quarter = determineQuarter(item.program_evaluation_date);
      if (quarter && item.program_evaluation) {
        quarterlyData[quarter].push(parseFloat(item.program_evaluation));
      }
    });
    console.log("filteredData",quarterlyData)

    return Object.values(quarterlyData).map(evals => 
      evals.length ? evals.reduce((a, b) => a + b, 0) / evals.length : 0
    );
  };

  // Helper function to determine quarter from date
  const determineQuarter = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth();
    if (month < 3) return 'Q1';
    if (month < 6) return 'Q2';
    if (month < 9) return 'Q3';
    return 'Q4';
  };

  return (
    <div
    style={{
      width: "1100px",
      height: "210px",
      background: "#fff",
      borderRadius: "10px",
      padding: "10px",
      boxShadow: "0px 0px 10px 0px #0000001a",
      marginBottom: "20px",
      position: "relative",
      margin: "10px",
      paddingBottom: "20px",
    }}
    >
      <div
         style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
           style={{
            fontSize: "15px",
            fontWeight: 600,
            color: "#6b6b6b",
            width: "100%",
          }}
        >
          {title}
        </Text>
        <Select
          size="xs"
          data={[
            { value: "2023", label: "2023" },
            { value: "2024", label: "2024" },
            { value: "2025", label: "2025" },
            { value: "2026", label: "2026" },
          ]}
          value={form.values["year"]}
          onChange={(e) => form.setFieldValue("year", e)}
        />
      
        {/* Department Selector */}
        <Select
        mx="xs"
          size="xs"
          data={form.values.departments}
          value={form.values["department"]}
          onChange={(e) => form.setFieldValue("department", e)}
        />
      </div>
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        notMerge={true}
        theme="echarts_theme"
        option={{
          xAxis: {
            type: "category",
            data: ["Q1", "Q2", "Q3", "Q4"],
          },
          yAxis: {
            type: "value",
            max: 100, // Set max value for yAxis
            textStyle:{
              fontSize: 11,
              color: "#666"
            }
          },
          grid: {
            left: "0",
            right: "0",
            top: "20",
            bottom: "20",
            containLabel: true,
          },
          series: [
            {
              data: calculateAverageEvaluation(form.values["department"], form.values["year"]),
              type: "line",
              smooth: true,
            },
          ],
        }}
      />
    </div>
  );
}