import {
  Avatar,
  Button,
  Center,
  NativeSelect,
  NumberInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";
import { tn, vtn } from "../../../translation";
import { useViewportSize } from "@mantine/hooks";
import { MIME_TYPES } from "@mantine/dropzone";
import {
  IconCheck,
  IconPhoto,
  IconSquareRoundedCheck,
  IconUpload,
} from "@tabler/icons-react";
import { useContext, useState, useEffect } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { useForm } from "@mantine/form";
import { MainDataProfile } from "./mainDataProfile";
import { BannersContext } from "../../../context/BannersContext";
import { AppContext } from "../../../context/AppContext";
import { containsArabicNumbers, convertArabicNumbersToEnglish, convertEnglishNumbersToArabic, vf } from "../../../main_components/Functions";

const pb: any = new PocketBase(CMS_ENDPOINT);

export function ProfileEdit({ setEditing, setWarnning }: any) {
  const [userData, setUserData] = useState(pb.authStore.model);
  //   useEffect(() => {
  //     pb.collection(pb.authStore.model?.collectionName).subscribe(pb.authStore.model.id, (e) => {
  //       pb.authStore.save(pb.authStore.token, e.record);
  //       setUserData(e.record);
  // });
  // return () => {
  //   pb.collection(pb.authStore.model?.collectionName).unsubscribe()
  // };
  //   }, []);
  const [phoneNumber, setPhoneNumber]: any = useState(
    userData?.mobile?.replace(userData?.country_mobile_number, "")
  );
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
    vtn("mobile"),
    vtn("university"),
    vtn("major"),
    vtn("GPA"),
    vtn("graduation_year"),
    vtn("semester"),
    vtn("degree"),
  ];
  const form = useForm({
    initialValues: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      gender: userData?.gender,
      mobile: userData?.mobile,
      about: userData?.about,
      case_study: userData?.case_study,
      university: userData?.university,
      major: userData?.major,
      GPA: parseFloat(userData?.GPA) ?? 0,
      cv: userData?.CV,
      country_mobile_number: userData?.country_mobile_number,
      graduation_year: userData?.graduation_year,
      semester: userData?.semester,
      GPA_out_of: userData?.GPA_out_of,
      degree: userData?.degree,
    },
    validate: {
      first_name: (value) =>
        !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
          ? /^[\u0621-\u064A\s]+$/.test(value)
            ? null
            : validate_text?.[7]
          : validate_text?.[1],
      last_name: (value) =>
        /^[\u0621-\u064A\s]+$/.test(value) ? null : validate_text?.[7],
      mobile: (value, values) =>
        value.length == values?.country_mobile_number.length + 9
          ? null
          : validate_text?.[8],
      university: (value) => (!value ? validate_text?.[9] : null),
      major: (value) => (!value ? validate_text?.[10] : null),
      GPA: (value) => (value <= 0 ? validate_text?.[11] : null),
      graduation_year: (value) => (!value ? validate_text?.[12] : null),
      semester: (value) => (!value ? validate_text?.[13] : null),
      degree: (value) => (!value ? validate_text?.[14] : null),
    },
  });

  const { locale, translation }: any = useContext(TranslationContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const { reloader, setReloader } = useContext(AppContext);

  const { height, width }: any = useViewportSize();
  const case_studies =  vf("case_study");
  const universities =  vf("university");
  const majors =  vf("major");
  const semesters =   vf("semester");
  const degrees = vf("degree");
  const graduation_years = vf("graduation_year");
  const genders = vf("gender");

  const [maxGPA, setMaxGPA] = useState(userData?.GPA_out_of);
 

  const [GPAfirst, setGPAfirst] = useState(
    locale == "en" ?
    userData?.GPA.toString():
    convertEnglishNumbersToArabic(userData?.GPA.toString())
  )
     
  console.log("GPAfirst",GPAfirst);
  const [count, setCount] = useState(0)
  useEffect(() => {
    setCount(count + 1);
    if(count > 0){
      form.setFieldValue("GPA", null);
      setGPAfirst("");
    }
  }, [maxGPA]);
  useEffect(() => {
      const formatted = convertArabicNumbersToEnglish(GPAfirst);
      const value:any = !isNaN(formatted)? parseFloat(formatted):null;
      if(
        value > 0
      ){
        if(value > maxGPA){
          form.setFieldValue("GPA", maxGPA);
          setGPAfirst(
            !containsArabicNumbers(GPAfirst) ?
            maxGPA.toString():
            convertEnglishNumbersToArabic(maxGPA.toString())
          );
        }else{
          form.setFieldValue("GPA", value);
        }
      }else{
        form.setFieldValue("GPA", null);
      }
  }, [GPAfirst]);


  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue("mobile", "");
    // }
  }, [form.values.country_mobile_number]);
  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue(
        "mobile",
        form.values.country_mobile_number + phoneNumber
      );
    // } else {
    //   form.setFieldValue("mobile", userData?.mobile);
    // }
  }, [phoneNumber]);
  const dialogMassage = tn("Data has been updated");

  const repeated_style1: any = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    flexDirection: width > 600 ? "row" : "column",
    marginBottom: "20px",
  };
  return width > 10 ? (
    <form
      onSubmit={form.onSubmit((value) => {
        const data_ = {
          first_name: value?.first_name,
          last_name: value?.last_name,
          gender:
            value?.gender,
          mobile: value?.mobile,
          about: value?.about,
          university: value?.university,
          major: value?.major,
          GPA: value?.GPA ? value?.GPA : 0,
          GPA_out_of: maxGPA,
          case_study: value?.case_study,
          country_mobile_number: value?.country_mobile_number,
          graduation_year: value.graduation_year,
          semester: value.semester,
          degree: value.degree,
        };

        const record = pb
          .collection("applicants")
          .update(userData?.id, data_)
          .then(async (records: any) => {
            // setBannerOpen(true);
            setTopBanner({
              message: dialogMassage,
              color: "#2DC071",
              top: "83px",
              backgroundColor: "#F2FFF7EB",
              icon: <IconSquareRoundedCheck />,
            });
            setEditing(false);
            const authData = await pb.collection('applicants').authRefresh();
            setReloader(!reloader);
            setUserData(pb?.authStore?.model);
          });
      })}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: width < 1500 ? "start" : "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          //   height: "100%",
          marginBottom: "20px",
          display: "flex",
          justifyContent: width < 1500 ? "start" : "center",
          alignItems: "center",
          flexDirection: width > 1500 ? "row" : "column",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
            padding: "10px",
            margin: "0px 10px",
            zIndex: 999,
            position: "absolute",
            top: width >= 500 ? "130px" : "170px",
            transform: width > 1500 ? "translateX(-510px)" : "translateX(0px)",
            left: width > 1500 ? "" : "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "stretch",
          }}
        >
          <Button
            disabled={!form.isDirty()}
            type="submit"
            mx="sm"
            // radius was 0 here
            style={
              form.isDirty()
                ? { background: "#69DCBF", height: "25px" }
                : { height: "25px" }
            }
          >
            {tn("Save")}
          </Button>

          <Button
            mx="sm"
            // radius was 0 here
            style={{ background: "#F9837C", height: "25px" }}
            onClick={() => {
              if (form.isDirty()) {
                setWarnning(true);
              } else {
                setEditing(false);
              }
            }}
          >
            {tn("Cancel")}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: width > 600 ? "row" : "column",
            width: width > 1500 ? "540px" : "100%",
            maxWidth: "700px",
            marginBottom: width > 1500 ? "0px" : "10px",
            justifyContent: "space-between",
            height: "100%",
            // height: "100%",
          }}
        >
          <MainDataProfile />
          <div
            style={{
              borderRadius: "10px",
              background: "#FFF",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
              padding: "20px",
              width: width > 1500 ? "270px" : width > 600 ? "49%" : "100%",
              margin:
                width > 1500
                  ? "0px 10px"
                  : width > 600
                  ? ""
                  : "10px 0px 0px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text fz="xl" fw="700" color="dimmed" pb={28} pt={10}>
              {tn("Overview of yourself")}
            </Text>
            <Textarea
              placeholder={tn("Write an overview of yourself")}
              {...form.getInputProps("about")}
              style={{
                width: "100%",
              }}
              styles={() => ({
                input: {
                  height: width > 600 ? "255px" : "150px",
                },
              })}
            />
          </div>
        </div>
        <div
          style={{
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.116)",
            padding: "30px 10px 0px",
            width: "100%",
            maxWidth: "700px",
            // margin: width > 1000 ? "0px 20px" : "20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Text fz="xl" fw="700" color="dimmed" pb={30}>
            {tn("Personal information")}
          </Text>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",

                gap: "20px",
                flexDirection: width > 600 ? "row" : "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("First Name")}
                </Text>
                <TextInput
                  size="xs"
                  {...form.getInputProps("first_name")}
                  style={{
                    width: "60%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Last Name")}
                </Text>
                <TextInput
                  size="xs"
                  {...form.getInputProps("last_name")}
                  style={{
                    width: "60%",
                  }}
                />
              </div>
            </div>
            <div style={repeated_style1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  // marginTop: "25px",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Mobile")}
                </Text>

                <TextInput
                  // required
                  onChange={(e) => 
                    setPhoneNumber( convertArabicNumbersToEnglish(e.target.value))
                  }
                  value={
                    locale == "ar"?convertEnglishNumbersToArabic(  form.values.mobile?.replace(
                      form.values.country_mobile_number,
                      ""
                    )):
                    form.values.mobile?.replace(
                    form.values.country_mobile_number,
                    ""
                  )}
                  error={form.errors.mobile}
                  rightSection={
                    <NativeSelect
                      {...form.getInputProps("country_mobile_number")}
                      // value={countryNum}
                      data={[
                        { value: "+1", label: "🇺🇸" },
                        { value: "+44", label: "🇬🇧" },
                        { value: "+33", label: "🇫🇷" },
                        { value: "+49", label: "🇩🇪" },
                        { value: "+81", label: "🇯🇵" },
                        { value: "+86", label: "🇨🇳" },
                        { value: "+91", label: "🇮🇳" },
                        { value: "+61", label: "🇦🇺" },
                        { value: "+7", label: "🇷🇺" },
                        { value: "+55", label: "🇧🇷" },
                        { value: "+20", label: "🇪🇬" },
                        { value: "+966", label: "🇸🇦" },
                        { value: "+971", label: "🇦🇪" },
                        { value: "+962", label: "🇯🇴" },
                        { value: "+965", label: "🇰🇼" },
                        { value: "+961", label: "🇱🇧" },
                        { value: "+964", label: "🇮🇶" },
                        { value: "+973", label: "🇧🇭" },
                        { value: "+968", label: "🇴🇲" },
                        { value: "+974", label: "🇶🇦" },
                        { value: "+52", label: "🇲🇽" },
                        { value: "+45", label: "🇩🇰" },
                        { value: "+64", label: "🇳🇿" },
                        { value: "+34", label: "🇪🇸" },
                        { value: "+39", label: "🇮🇹" },
                        { value: "+41", label: "🇨🇭" },
                      ]}
                      styles={(theme) => ({
                        label: {
                          color: "#ffffff",
                        },
                        input: {
                          fontSize: "13px",
                          border: "0px solid #69DCBF",
                          borderRadius: "0px",
                          color: "white",
                          backgroundColor: "transparent",
                          // borderBottom: "1px solid #69dcbf86",
                          "&:focus-within": {
                            // borderBottom: "1px solid #69DCBF",
                          },
                        },
                      })}
                    />
                  }
                  rightSectionWidth={60}
                  withAsterisk={false}
                  styles={(theme) => ({
                    label: {
                      // marginLeft: "-30px",
                      color: "white",
                    },
                    input: {
                      // textAlign: "end",
                      // border: "0px solid #69DCBF",
                      // borderRadius: "0px",
                      // color: "white",
                      // backgroundColor: "transparent",
                      // borderBottom: "1px solid #69dcbf86",
                      // "&:focus-within": {
                      //   border: "0px solid #69DCBF",
                      //   borderBottom: "1px solid #69DCBF",
                      // },
                    },
                    error: {
                      marginBottom: "-13px",
                      fontSize: "12px",
                    },
                  })}
                  size="xs"
                  style={{
                    width: "60%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Email")}
                </Text>
                <BoxStyleF text={userData?.email} />
              </div>
            </div>
            <div style={repeated_style1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Gender")}
                </Text>
                <SegmentedControl
                  size="xs"
                  {...form.getInputProps("gender")}
                  //   style={{height:"30px"}}
                  styles={() => ({
                    root: {
                      width: "60%",
                      color: "#737373",
                      backgroundColor: "transparent",
                      border: "1px solid #69dcbfdc",
                    },
                    label: {
                      color: "#737373",
                      height: "25px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 10px",
                      "&:hover": {
                        color: "#737373",
                      },
                      '&[data-active="true"]': {
                        color: "#737373",
                      },
                      "&[data-active]:hover": {
                        color: "#737373",
                      },
                    },
                    indicator: {
                      color: "#737373",
                      backgroundColor: "#69DCBF !important",
                      borderRadius: "2px",
                    },
                    control: {
                      width: "100%",
                      color: "#737373",
                      //    backgroundColor: "#efefef5f !important",
                      borderRadius: "2px",
                    },
                    controlActive: { color: "#737373" },
                  })}
                  data={
                    genders?.map((option: any) => ({
                      value: option?.name,
                      label: option?.[locale],
                    }))
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Case Study")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  data={case_studies?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("case_study")}
                  style={{ width: "60%" }}
                />
              </div>
            </div>
            <div style={repeated_style1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("University")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  data={universities?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("university")}
                  style={{ width: "60%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Degree")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  data={degrees?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("degree")}
                  style={{ width: "60%" }}
                />
              </div>
            </div>
            <div style={repeated_style1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Major")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  data={majors?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("major")}
                  style={{ width: "60%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("GPA")}
                </Text>

                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <TextInput
                
                    size="xs"
                    mr="sm"
                    error={form.errors.GPA}
                    onBlur={(e) => {
                      if (!form?.values?.GPA) {
                        setGPAfirst("");
                      }
                  }}
                  onChange={(e) => {
                    setGPAfirst(e.target.value);
                  }}
                  value={
                    GPAfirst
                  }
                    style={{ width: "calc(100% - 10px)" }}
                    withAsterisk={false}
                  />
                  <div
                    style={{
                      border: "1px solid #69dcbfdc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "30px",
                    }}
                  >
                    <SegmentedControl
                      size="xs"
                      onChange={(value) => {
                        setMaxGPA(parseInt(value));
                        form.setFieldValue("GPA_out_of", value);
                      }}
                      defaultValue={form?.values?.GPA_out_of}
                      style={{ width: "110%" }}
                      styles={() => ({
                        root: {
                          color: "#000000",
                          backgroundColor: "transparent",
                          // border: "1px solid #69dcbfdc",
                        },

                        indicator: {
                          color: "#000000",
                          backgroundColor: "#69DCBF !important",
                          borderRadius: "2px",
                        },
                        control: {
                          // width: "60px",
                          color: "#000000",
                          // backgroundColor: "#9191912b !important",
                          borderRadius: "2px",
                        },
                        controlActive: { color: "#000000" },
                      })}
                      data={[
                        {
                          label: locale == "en" ? "5" : "٥",
                          value: "5",
                        },
                        {
                          label: locale == "en" ? "4" : "٤",
                          value: "4",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={repeated_style1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Graduation Year")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  data={
                    graduation_years?.[0]
                      ? graduation_years?.map((option: any) => ({
                        value: option?.name,
                          label: option?.[locale],
                        })
                      )
                      : []
                  }
                  {...form.getInputProps("graduation_year")}
                  style={{ width: "60%" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text color="#676767" size="xs" fw="700">
                  {tn("Semester")}
                </Text>
                <Select
                  size="xs"
                  searchable
                  data={semesters?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))}
                  {...form.getInputProps("semester")}
                  style={{ width: "60%" }}
                />
              </div>
            </div>
            <div style={repeated_style1}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                  flexDirection: width > 600 ? "row" : "column",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      minWidth: "70px",
                    }}
                    color="#676767"
                    size="xs"
                    fw="700"
                  >
                    {tn("CV")}
                  </Text>
                  {userData?.cv ? (
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#676767",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                      }}
                      href={`${CMS_ENDPOINT}/api/files/${userData?.collectionName}/${userData?.id}/${userData?.cv}?token=`}
                      download={userData?.cv}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text
                        fz="xs"
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          direction: "ltr",
                        }}
                      >
                        {userData?.cv}
                      </Text>
                    </a>
                  ) : (
                    <Text color="#676767" size="xs" fw="700">
                      {tn("No Data")}
                    </Text>
                  )}
                </div>{" "}
                <div
                  style={{
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <></>
  );
}

const BoxStyleF: any = ({ text }: any) => {
  const boxStyle: any = {
    height: "1.875rem",
    boxSizing: "border-box",
    fontSize: text?.length > 15 ? "60%" : "14px",
    minWidth: "60%",
    maxWidth: "60%",
    color: "#727272",
    border: "0.0625rem solid #ced4da",
    backgroundColor: "#fff",
    paddingRight: "calc(2.25rem / 3)",
    paddingLeft: "calc(2.25rem / 3)",
    borderRadius: "0.25rem",
    display: "flex",
    alignItems: "center",
    // justifyContent: "end",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // wordWrap: "break-word",
    // maxHeight: "3.6em",
    // lineHeight: "1.8em",
  };
  return <div style={boxStyle}>{text}</div>;
};
