import {
  TextInput,
  PasswordInput,
  Button,
  Text,
  Anchor,
  Avatar,
  FileButton,
  Tabs,
  Divider,
  Select,
  NumberInput,
  SegmentedControl,
  Textarea,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { isNotEmpty, useForm } from "@mantine/form";
import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../context/TranslationContext";
import { tn, vtn } from "../../../translation";
import PocketBase from "pocketbase";
import { CMS_ENDPOINT } from "../../../constants";
import { BannersContext } from "../../../context/BannersContext";
import {
  IconCheck,
  IconKey,
  IconMail,
  IconPencil,
  IconSquareRoundedCheck,
  IconX,
} from "@tabler/icons-react";
import { Helmet } from "react-helmet";
import { TopShapeAuth } from "../components/TopShapeAuth";
import { motion } from "framer-motion";
import { AppContext } from "../../../context/AppContext";
import { MainDataProfile } from "./MainDataProfile";
import { containsArabicNumbers, convertArabicNumbersToEnglish, convertEnglishNumbersToArabic, vf } from "../../../main_components/Functions";

export function ProfileEdit({ setWarnning, setEditing }) {
  const pb: any = new PocketBase(CMS_ENDPOINT);
  const [avatar, setAvatar] = useState("");
  const navigate = useNavigate();
  const [buttonLoading]: any = useState(false);
   const [countryNum, setCountryNum] = useState("+966");
  const { locale }: any = useContext(TranslationContext);
  const { setTopBanner }: any = useContext(BannersContext);
  const case_studies = vf("case_study");
  const universities = vf("university");
  const majors = vf("major");
  const semesters = vf("semester");
  const degrees = vf("degree");
  const graduation_years = vf("graduation_year");
  const GPAerror = vtn("GPA");
  const MobileError = vtn("mobile");
  const [userData, setUserData] = useState(pb.authStore.model);
  const { reloader, setReloader } = useContext(AppContext);
  const [onHover, setOnHover]: any = useState(false);
  const [phoneNumber, setPhoneNumber]: any = useState(
    userData?.mobile?.replace(userData?.country_mobile_number, "")
  ); 
  const validate_text = [
    vtn("case_study"),
    vtn("first_name"),
    vtn("last_name"),
    vtn("taken_email"),
    vtn("email"),
    vtn("password"),
    vtn("password_comfirm"),
    vtn("arabic_name"),
    vtn("mobile"),
    vtn("university"),
    vtn("major"),
    vtn("GPA"),
    vtn("graduation_year"),
    vtn("semester"),
    vtn("degree"),
  ];
  const form = useForm({
    initialValues: {
      email: userData?.email,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      gender: userData?.gender,
      mobile: userData?.mobile,
      about: userData?.about,
      case_study: userData?.case_study,
      university: userData?.university,
      major: userData?.major,
      GPA: parseFloat(userData?.GPA) ?? 0,
      CV: userData?.cv ?? null,
      country_mobile_number: userData?.country_mobile_number,
      graduation_year: userData?.graduation_year,
      semester: userData?.semester,
      GPA_out_of: userData?.GPA_out_of,
      degree: userData?.degree,
    },
    validate: {
      first_name: (value) =>
        !/^\S+ \S+$/.test(value) && value.length > 2 && !/[0-9]/.test(value)
          ? /^[\u0621-\u064A\s]+$/.test(value)
            ? null
            : validate_text?.[7]
          : validate_text?.[1],
      last_name: (value) =>
        /^[\u0621-\u064A\s]+$/.test(value) ? null : validate_text?.[7],
      mobile: (value, values) =>
        value.length == values?.country_mobile_number.length + 9
          ? null
          : validate_text?.[8],
      university: (value) => (!value ? validate_text?.[9] : null),
      major: (value) => (!value ? validate_text?.[10] : null),
      GPA: (value) => (value <= 0 ? validate_text?.[11] : null),
      graduation_year: (value) => (!value ? validate_text?.[12] : null),
      semester: (value) => (!value ? validate_text?.[13] : null),
      degree: (value) => (!value ? validate_text?.[14] : null),
    },
  });

 
  const [maxGPA, setMaxGPA] = useState(userData?.GPA_out_of);
 

  const [GPAfirst, setGPAfirst] = useState(
    locale == "en" ?
    userData?.GPA.toString():
    convertEnglishNumbersToArabic(userData?.GPA.toString())
  )
     
  console.log("GPAfirst",GPAfirst);
  const [count, setCount] = useState(0)
  useEffect(() => {
    setCount(count + 1);
    if(count > 0){
      form.setFieldValue("GPA", null);
      setGPAfirst("");
    }
  }, [maxGPA]);
  useEffect(() => {
      const formatted = convertArabicNumbersToEnglish(GPAfirst);
      const value:any = !isNaN(formatted)? parseFloat(formatted):null;
      if(
        value > 0
      ){
        if(value > maxGPA){
          form.setFieldValue("GPA", maxGPA);
          setGPAfirst(
            !containsArabicNumbers(GPAfirst) ?
            maxGPA.toString():
            convertEnglishNumbersToArabic(maxGPA.toString())
          );
        }else{
          form.setFieldValue("GPA", value);
        }
      }else{
        form.setFieldValue("GPA", null);
      }
  }, [GPAfirst]);

  
  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue("mobile", "");
    // }
  }, [form.values.country_mobile_number]);
  useEffect(() => {
    // if (phoneNumber.length > 0) {
      form.setFieldValue(
        "mobile",
        form.values.country_mobile_number + phoneNumber
      );
    // } else {
    //   form.setFieldValue("mobile", userData?.mobile);
    // }
  }, [phoneNumber]);
  const dialogMassage = tn("Data has been updated");
  console.log(form);
  return (
    <>
      <Helmet>
        <title>{tn("Login") + " / " + tn("Coophub")}</title>
      </Helmet>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          height: "100vh",
          width: "100%",
        }}
        onSubmit={form.onSubmit( async (value) => {
          const data_ = {
            first_name: value?.first_name,
            last_name: value?.last_name,
            gender:
              value?.gender,
            mobile: value?.mobile,
            about: value?.about,
            university: value?.university,
            major: value?.major,
            GPA: value?.GPA ? value?.GPA : 0,
            GPA_out_of: maxGPA,
            case_study: value?.case_study,
            country_mobile_number: value?.country_mobile_number,
            graduation_year: value.graduation_year,
            semester: value.semester,
            degree: value.degree,
          };
          console.log(data_);
          const record = await pb
            .collection("applicants")
            .update(userData?.id, data_)
            if(value?.CV?.name){
              // use the const formData = new FormData();
              const formData = new FormData();
              formData.append("cv", value?.CV);
              const recorssd = await pb
                .collection("applicants")
                .update(userData?.id, formData);
            }else if (value?.CV == null){
              const recorssd = await pb
                .collection("applicants")
                .update(userData?.id, {
                  cv: null,
                });
            }
            setTopBanner({
              message: dialogMassage,
              color: "#2DC071",
              top: "83px",
              backgroundColor: "#F2FFF7EB",
              icon: <IconSquareRoundedCheck />,
            });
            setEditing(false);
            setReloader(!reloader);
            setUserData(pb?.authStore?.model);
        })}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            padding: "20px",
            margin: "40px 20px 20px",
            gap: "20px",
            width: "calc(100% - 40px)",
            borderRadius: "15px",
            boxShadow: "1px 3px 30px 0px rgba(0, 0, 0, 0.13)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              gap: "20px",
            }}
          >
            <Button
            type="submit"
            radius={10.112}
              color="color2"
              style={{
                width: "100%",
              }}
            >
              {tn("Save Changes")}
            </Button>
            <Button
            radius={10.112}
              
              style={{
                backgroundColor:"#F9837C",
                width: "100%",
              }}
              onClick={() => {
                setWarnning(true);
              }}
            >
              {tn("Cancel")}
            </Button>
          </div>
          <MainDataProfile />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <Text color="#21033F" fw="600">
              {tn("Gender")}
            </Text>
            <Tabs
              styles={() => ({
                tabLabel: {
                  color: "#69DCBF",
                },
              })}
              color="color2"
              value={form.values?.gender}
              onTabChange={(value) => {
                form.setFieldValue("gender", value);
                console.log(value);
              }}
            >
              <Tabs.List>
                <Tabs.Tab value="male"> {tn("Male")}</Tabs.Tab>
                <Tabs.Tab value="female">{tn("Female")}</Tabs.Tab>
              </Tabs.List>
            </Tabs>
          </div>
          <Divider
            style={{
              width: "100%",
              margin: "10px 0px",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <TextInput
              {...form.getInputProps("first_name")}
              label={tn("First Name")}
              {...InputStyle}
            />
            <TextInput
              {...form.getInputProps("last_name")}
              label={tn("Last Name")}
              {...InputStyle}
            />
          </div>
          <TextInput
            {...form.getInputProps("email")}
            label={tn("Email")}
            disabled
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("case_study")}
            label={tn("Case Study")}
            {...InputStyle}
            data={
              case_studies?.[0]
                ? case_studies?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
          />

          <TextInput
           
           onChange={(e) => 
            setPhoneNumber( convertArabicNumbersToEnglish(e.target.value))
          }
          value={
            locale == "ar"?convertEnglishNumbersToArabic(  form.values.mobile?.replace(
              form.values.country_mobile_number,
              ""
            )):
            form.values.mobile?.replace(
            form.values.country_mobile_number,
            ""
          )}
           error={form.errors.mobile}
            label={tn("Mobile")}
            rightSection={
              <Select
                {...form.getInputProps("country_mobile_number")}
                size="xs"
                // onChange={(e) => setCountryNum(e)}
                // value={countryNum}
                data={[
                  { value: "+1", label: "🇺🇸" },
                  { value: "+44", label: "🇬🇧" },
                  { value: "+33", label: "🇫🇷" },
                  { value: "+49", label: "🇩🇪" },
                  { value: "+81", label: "🇯🇵" },
                  { value: "+86", label: "🇨🇳" },
                  { value: "+91", label: "🇮🇳" },
                  { value: "+61", label: "🇦🇺" },
                  { value: "+7", label: "🇷🇺" },
                  { value: "+55", label: "🇧🇷" },
                  { value: "+20", label: "🇪🇬" },
                  { value: "+966", label: "🇸🇦" },
                  { value: "+971", label: "🇦🇪" },
                  { value: "+962", label: "🇯🇴" },
                  { value: "+965", label: "🇰🇼" },
                  { value: "+961", label: "🇱🇧" },
                  { value: "+964", label: "🇮🇶" },
                  { value: "+973", label: "🇧🇭" },
                  { value: "+968", label: "🇴🇲" },
                  { value: "+974", label: "🇶🇦" },
                  { value: "+52", label: "🇲🇽" },
                  { value: "+45", label: "🇩🇰" },
                  { value: "+64", label: "🇳🇿" },
                  { value: "+34", label: "🇪🇸" },
                  { value: "+39", label: "🇮🇹" },
                  { value: "+41", label: "🇨🇭" },
                ]}
                styles={(theme) => ({
                  input: {
                    fontSize: "13px",
                    border: "0px solid #69DCBF",
                    borderRadius: "0px",
                    color: "white",
                    backgroundColor: "transparent",
                  },
                })}
              />
            }
            rightSectionWidth={60}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("university")}
            searchable
            data={
              universities?.[0]
                ? universities?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            label={tn("University")}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("degree")}
            searchable
            data={
              degrees?.[0]
                ? degrees?.map((option: any) => ({
                    value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            label={tn("Degree")}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("major")}
            searchable
            data={
              majors?.[0]
                ? majors?.map((option: any) => ({
                  value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            label={tn("Major")}
            {...InputStyle}
          />
          <TextInput
             error={form.errors.GPA}
             onBlur={(e) => {
               if (!form?.values?.GPA) {
                 setGPAfirst("");
               }
           }}
           onChange={(e) => {
             setGPAfirst(e.target.value);
           }}
           value={
             GPAfirst
           }
            label={tn("GPA")}
            {...InputStyle}
            rightSection={
              <SegmentedControl
                onChange={(value) => {
                  setMaxGPA(parseInt(value));
                }}
                style={{ width: "70px" }}
                styles={() => ({
                  root: {
                    color: "#6f6f6f",
                    backgroundColor: "transparent",
                  },
                  label: {
                    color: "#6f6f6f",
                    height: "25px",
                    borderRadius: "2px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 10px",
                    "&:hover": {
                      color: "#fff",
                    },
                    '&[data-active="true"]': {
                      color: "#fff",
                    },
                    "&[data-active]:hover": {
                      color: "#fff",
                    },
                  },
                  indicator: {
                    color: "#6f6f6f",
                    backgroundColor: "rgba(33, 3, 63, 0.70) !important",
                    borderRadius: "2px",
                  },
                  control: {
                    // width: "60px",
                    color: "#6f6f6f",
                    // backgroundColor: "#91919160 !important",
                    borderRadius: "2px",
                  },
                  controlActive: { color: "#6f6f6f" },
                })}
                data={[
                  { label: locale == "en" ? "5" : "٥", value: "5" },
                  { label: locale == "en" ? "4" : "٤", value: "4" },
                ]}
              />
            }
            rightSectionWidth={70}
          />
          <Select
            {...form.getInputProps("graduation_year")}
            data={
              graduation_years?.[0]
                ? graduation_years?.map((option: any) => ({
                  value: option?.name,
                    label: option?.[locale],
                  }))
                : []
            }
            label={tn("Graduation Year")}
            {...InputStyle}
          />
          <Select
            {...form.getInputProps("semester")}
            searchable
            data={
              semesters?.[0]
                ? semesters?.map((option: any) => ({
                  value: option?.name,
                  label: option?.[locale],
                  }))
                : []
            }
            label={tn("Semester")}
            {...InputStyle}
          />
          <Divider
            style={{
              width: "100%",
              margin: "10px 0px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <Text
              style={{
                fontSize: "15px",
                width: "100%",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                color: "#21033F",
              }}
            >
              {form?.values?.CV ? (form?.values?.CV?.name ?? userData?.cv ) : tn("CV")}
            </Text>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "20px",
                width: "100%",
              }}
            >
              <FileButton
                {...form.getInputProps("CV")}
                accept=".pdf"
              >
                {(props: any) => (
                  <Button
                    {...props}
                    color="color3"
                    radius="10.112px"
                    style={{
                      width: "100%",
                    }}
                  >
                    {tn("Upload")}
                  </Button>
                )}
              </FileButton>
              <Button
                disabled={!form.values?.CV}
                onClick={async () => {
                  form.setFieldValue("CV", null);
                }}
                radius="10.112px"
                style={{
                  width: "100%",
                  background: form.values?.CV ? "#F9837C" : "",
                }}
              >
                {tn("Delete CV")}
              </Button>
            </div>
          </div>
          {form.errors.CV && (
            <Text
              style={{
                color: "red",
                fontSize: "12px",
                textAlign: "center",
                width: "100%",
              }}
            >
              {form.errors.CV}
            </Text>
          )}

          <Textarea
            {...form.getInputProps("about")}
            label={tn("About")}
            {...InputStyle}
            required={false}
          />
        </div>

        <div
          style={{
          minHeight: "50px",
          width: "100%",
          }}
          />
      </form>
    </>
  );
}

const divider = (
  <div
    style={{
      width: "1px",
      height: "15px",
      background: "#767676",
      margin: "0px 5px",
    }}
  />
);

const InputStyle = {
  withAsterisk: false,
  required: true,
  style: {
    width: "100%",
  },
  size: "lg",
  styles: () => ({
    label: {
      color: "#21033F",
      transform: "translate(10px, -3.5px)",
    },
    input: {
      borderRadius: "10px",
      border: "1px solid #000000",
    },
    error: {
      marginBottom: "-13px",
      fontSize: "12px",
    },
  }),
};

const IconStyle = {
  size: "1.5rem",
  stroke: 1.5,
  style: {
    color: "#a1a1a1",
  },
};
