import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { tn } from "../../../translation";
import { Loader, Text } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { EvaluationForm } from "./EvaluationForm";
import { EvaluationView } from "./EvaluationView";
import { pb } from "../../../config";
import { AppContext } from "../../../context/AppContext";
import { set } from "lodash";

export function EvaluationModels({ model_type }: any) {
    const [chooseButtonLoading, setChooseButtonLoading] = useState(false);
    const [createButtonLoading, setCreateButtonLoading] = useState(false);
    const [company, setCompany]:any = useState({})
  const { reloader, setReloader }: any = useContext(AppContext);
  const { model }: any = useParams();
  const userPermission = pb.authStore.model?.expand?.permission?.permissions
  useEffect(() => {
    const getModels = async () => {
      const models = await pb.collection("evaluation_models").getFullList({
        sort: "company , -created",
        filter: `model_type="${model_type}"`,
      });
      const company = await pb.collection("companies").getFullList({
        filter: `id="${pb.authStore.model?.company}"`,
      });
        setCompany(company?.[0])
      setModels(models);
      setChooseButtonLoading(false);
        setCreateButtonLoading(false);
    };
    getModels();
  }, [reloader]);
  const navigate = useNavigate();
  const [models, setModels]: any = useState([]);
  console.log("modelsmodels", models);
  const [isEdit, setIsEdit] = useState(false);
  const selectedModel =
    models.find((item: any) => item.id == model) ??
    models?.find(
      (item: any) =>
        item.id ==
        company?.current_models?.[model_type]
    );
    if (!selectedModel) {
        return <div>
            <Loader />
        </div>
    }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
          width: "100%",
          padding: "0px 20px",
        }}
      >
        <Text
          style={{
            color: "rgba(0, 0, 0, 0.70)",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          {tn("Models:")}
        </Text>
        <ScrollableDiv
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "10px",
            width: "100%",
            padding: "0px 20px",
            overflowX: "scroll",
          }}
        >
          {models?.[0] ? (
            models?.map((item: any) => {
              return (
                <div
                  style={{
                    backgroundColor:
                      item.id == selectedModel?.id && model != "new"
                        ? "#21033F"
                        : "#fff",
                    borderRadius: "10px",
                    color:
                      item.id == selectedModel?.id && model != "new"
                        ? "#fff"
                        : "#21033F",
                    border:
                      company?.current_models?.[
                        model_type
                      ] == item.id
                        ? "2px solid #21033F"
                        : "0px solid #fff",
                    fontSize: "15px",
                    padding: "5px 15px",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                  onClick={() => {
                    navigate(`/recruiter/models/${model_type}/${item.id}`);
                    setIsEdit(false);
                  }}
                >
                  <Text> {item?.name}</Text>
                </div>
              );
            })
          ) : (
            <></>
          )}
         {userPermission?.includes("6.1.2") && <div
            style={{
              backgroundColor: model != "new" ? "#FFF" : "#21033F",
              borderRadius: "10px",
              color: model != "new" ? "#21033F" : "#FFF",
              fontSize: "15px",
              padding: "5px 15px",
              gap: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
            onClick={async () => {
              navigate(`/recruiter/models/${model_type}/new`);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M7.5 1.40625C6.29477 1.40625 5.11661 1.76364 4.1145 2.43323C3.11238 3.10282 2.33133 4.05454 1.87011 5.16802C1.40889 6.28151 1.28821 7.50676 1.52334 8.68883C1.75847 9.8709 2.33884 10.9567 3.19107 11.8089C4.0433 12.6612 5.1291 13.2415 6.31117 13.4767C7.49324 13.7118 8.71849 13.5911 9.83198 13.1299C10.9455 12.6687 11.8972 11.8876 12.5668 10.8855C13.2364 9.88339 13.5938 8.70523 13.5938 7.5C13.5917 5.88446 12.9491 4.33566 11.8067 3.19329C10.6643 2.05093 9.11555 1.40827 7.5 1.40625ZM9.84375 7.96875H7.96875V9.84375C7.96875 9.96807 7.91937 10.0873 7.83146 10.1752C7.74355 10.2631 7.62432 10.3125 7.5 10.3125C7.37568 10.3125 7.25645 10.2631 7.16855 10.1752C7.08064 10.0873 7.03125 9.96807 7.03125 9.84375V7.96875H5.15625C5.03193 7.96875 4.9127 7.91936 4.8248 7.83146C4.73689 7.74355 4.6875 7.62432 4.6875 7.5C4.6875 7.37568 4.73689 7.25645 4.8248 7.16854C4.9127 7.08064 5.03193 7.03125 5.15625 7.03125H7.03125V5.15625C7.03125 5.03193 7.08064 4.9127 7.16855 4.82479C7.25645 4.73689 7.37568 4.6875 7.5 4.6875C7.62432 4.6875 7.74355 4.73689 7.83146 4.82479C7.91937 4.9127 7.96875 5.03193 7.96875 5.15625V7.03125H9.84375C9.96807 7.03125 10.0873 7.08064 10.1752 7.16854C10.2631 7.25645 10.3125 7.37568 10.3125 7.5C10.3125 7.62432 10.2631 7.74355 10.1752 7.83146C10.0873 7.91936 9.96807 7.96875 9.84375 7.96875Z"
                fill={model != "new" ? "#21033F" : "#FFF"}
              />
            </svg>
            <Text>{tn("New model")}</Text>
          </div>}
        </ScrollableDiv>
      </div>
      {(userPermission?.includes("6.1.2") && (isEdit || model == "new")) ? (
        <EvaluationForm
        submitLoading={createButtonLoading}
        model={model}
          data={model == "new" ? {} : (selectedModel ?? {})}
          cancelButtonText={model != "new" ? tn("Cancel") : undefined}
          submitButtonText={model == "new" ? tn("Create") : tn("Update")}
          onSubmit={async (data: any) => {
            console.log("datadatadata", data);
            if (model == "new") {
                setCreateButtonLoading(true)
              const new_model = await pb
                .collection("evaluation_models")
                .create({
                  ...data,
                  model_type: model_type,
                  company: pb.authStore.model?.company,
                });
                
              navigate(`/recruiter/models/${model_type}/${new_model.id}`);
            } else {
              const update_model = await pb
                .collection("evaluation_models")
                .update(model ?? 
                  selectedModel?.id
                  , data);
            }
            setReloader(!reloader);
            setIsEdit(false);
          }}
          onCancelButtonClick={() => {
            setIsEdit(false);
          }}
        />
      ) : (
        <EvaluationView
        userPermission={userPermission}
          data={selectedModel ?? {}}
          EditButtonText={selectedModel?.company ? tn("Update") : undefined}
          SubmitButtonText={
            company?.current_models?.[model_type] ==
            selectedModel?.id
              ? tn("Selected")
              : tn("Select")
          }
          submitButtonDisabled={
            company?.current_models?.[model_type] ==
            selectedModel?.id
          }
          name={tn("Name")}
          EditButtonClick={() => {
            setIsEdit(true);
          }}
          submitButtonLoading={chooseButtonLoading}
          SubmitButtonClick={async (data: any) => {
            setChooseButtonLoading(true)
            console.log("companycompany", company);
            const current_models = company?.current_models ?? {};
            const update_company = await pb
              .collection("companies")
              .update(company?.id, {
                current_models: {
                  ...current_models,
                  [model_type]: selectedModel.id,
                },
              });

            pb.collection("recruiters").authRefresh(
              {},
              {
                expand: "company, permission", // replace with your relation field name
              }
            );
          
            setReloader(!reloader);
          }}
          DeleteButtonText={selectedModel?.company ? tn("Delete") : undefined}
          DeleteButtonClick={async () => {
            const delete_model = await pb
              .collection("evaluation_models")
              .delete(selectedModel.id);
            if (
              company?.current_models?.[
                model_type
              ] == selectedModel.id
            ) {
              //switch to the main model if the selected model is deleted
              const current_models = company?.current_models ?? {};
              const update_company = await pb
                .collection("companies")
                .update(company?.id, {
                  current_models: {
                    ...current_models,
                    [model_type]:
                      models?.find((item: any) => !item.company)?.id ,
                  },
                });
              pb.collection("recruiters").authRefresh(
                {},
                {
                  expand: "company, permission", // replace with your relation field name
                }
              );
            }
            setReloader(!reloader);
          }}
        />
      )}

      <div />
    </div>
  );
}

const ScrollableDiv = styled.div`
  scrollbar-width: none; /* For Firefox */
  &::-webkit-scrollbar {
    display: none; /* For WebKit browsers like Chrome and Safari */
  }
`;
